import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Metadata from '../components/Metadata';
import Section from '../components/Section';
import { StyledContainer } from '../layouts/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const StyledCourse = styled.div`
  display: flex;
  flex-direction: column;
  border: var(--border-medium);
  padding: 2rem;
  border-radius: 16px;
  background-color: white;
  max-width: 25rem;
  margin: 0 auto;

  @media (min-width: 920px) {
    text-align: center;
  }

  a {
    padding: 1rem 1.5rem;
    background-color: var(--color-primary);
    color: white;
    text-decoration: none;
    min-width: fit-content;
    border: var(--border-small);
    border-radius: 16px;

    &:hover,
    &:focus-visible {
      color: var(--color-primary);
      background-color: white;
    }
  }
`;

const StyledCourseInformation = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
  }

  img {
    width: 75%;
    margin: 0 auto;
  }

  a {
    align-self: flex-start;
    margin: 0 auto;
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 900;
  }

  p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const StyledCourseContainer = styled.div`
  display: grid;
  grid-gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledHeadingImage = styled.div`
  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;

  p {
    font-size: 1.5rem;
  }
`;

function Fortbildung() {
  return (
    <>
      <Metadata title='Fortbildung' />
      <StyledContainer>
        <h1>Fortbildung</h1>
        <Section>
          <StyledHeadingImage>
            <div>
              <p>
                Sind Sie auf der Suche nach einer Fortbildung rund um das Thema
                Autismus?
              </p>
            </div>
            <StaticImage src='../images/Schulungen.png' alt='Glühbirne' />
          </StyledHeadingImage>
        </Section>
        <Section>
          <p>Dann sind wir gerne Ihr Ansprechpartner.</p>
          <p>
            Wir bieten interessierten Institutionen Fortbildungen zum Thema
            Autismus an (online oder vor Ort).
          </p>
          <p>
            Wir vermitteln Ihnen das nötige Wissen und geben Ihnen Anregungen
            mit auf den Weg – natürlich individualisiert auf Ihr Arbeitsfeld.
          </p>
          <p>
            Da wir die konkreten Inhalte einer Fortbildung gerne auf Ihren
            Bedarf anpassen, freuen wir uns auf Ihre Anfrage:
            info@lernen-rueckenwind.de
          </p>
        </Section>
        <Section anchor='kurse'>
          <p style={{ fontSize: '1.5rem', fontWeight: 900 }}>
            Eine Auswahl an möglichen Themen:
          </p>
          <StyledCourseContainer>
            <StyledCourse>
              <StyledCourseInformation>
                <div style={{ marginBottom: '2rem' }}>
                  <h4>Kommunikation und Autismus</h4>
                  <p>
                    Kommunikation ist der Schlüssel zum Erfolg - doch was ist,
                    wenn der Schlüssel nicht ins Schloss passt und die Tür zu
                    einer uneingeschränkten Kommunikation verschlossen bleibt?
                  </p>
                  <StaticImage
                    src='../images/Kommunikation_und_Autismus.jpg'
                    alt='Kommunikation und Autismus'
                  />
                </div>
                <a
                  href='/Fortbildungsflyer_Lernen mit Rückenwind_Kommunikation und Autismus.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span>
                    Mehr erfahren <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                </a>
              </StyledCourseInformation>
            </StyledCourse>
            <StyledCourse>
              <StyledCourseInformation>
                <div style={{ marginBottom: '2rem' }}>
                  <h4>Wer hilft dem Inklusionshelfer?</h4>
                  <p>
                    Das Aufgabengebiet eines Inklusionshelfers ist sehr
                    vielfältig, genauso wie die Berufsbezeichnung.
                    Schulbegleiter… , Inklusionsassistenten oder I-Helfer tragen
                    sehr viel Verantwortung. Sie müssen flexibel, professionell
                    und kompetent auf die verschiedensten Situationen reagieren,
                    ein fundiertes Wissen über verschiedene Behinderungsbilder
                    mitbringen und ständiger Ansprechpartner für ihren
                    Schützling und die Pädagogen sein.
                  </p>
                  <StaticImage
                    src='../images/Integrationshelfer.jpg'
                    alt='Integrationshelfer'
                  />
                </div>
                <a
                  href='/Fortbildungsflyer_Lernen mit Rückenwind_Integrationshelfer.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span>
                    Mehr erfahren <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                </a>
              </StyledCourseInformation>
            </StyledCourse>
            <StyledCourse>
              <StyledCourseInformation>
                <div style={{ marginBottom: '2rem' }}>
                  <h4>Neurodiversität & Schule</h4>
                  <p>
                    Das Konzept Neurodiversität beschreibt neurobiologische
                    Unterschiede als natürliche Form der menschlichen Vielfalt.
                    Alle Menschen sind demnach als neurodivers zu betrachten, da
                    jedes Gehirn unterschiedlich verdrahtet ist. Manche Gehirne
                    ähneln sich mehr als andere. Unsere Gesellschaft ist stärker
                    auf die Bedürfnisse der sogenannten Neuro-Mehrheit ausgelegt
                    und vergisst oft die Bedürfnisse sogenannten Neuro-
                    Minderheiten. Hierzu werden u.a. ADHS, Autismus, Legasthenie
                    und Dyskalkulie gezählt.
                  </p>
                  <StaticImage
                    src='../images/Neurodiversität_und_Schule.jpg'
                    alt='Neurodiversität'
                  />
                </div>
                <a
                  href='/Fortbildungsflyer_Lernen mit Rückenwind_Neurodiversität und Schule.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span>
                    Mehr erfahren <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                </a>
              </StyledCourseInformation>
            </StyledCourse>
            <StyledCourse>
              <StyledCourseInformation>
                <div style={{ marginBottom: '2rem' }}>
                  <h4>Eskalation vorprogrammiert</h4>
                  <p>
                    In unserer pädagogisch-therapeutischen Arbeit können uns
                    verschiedene Herausforderungen begegnen. Eine davon ist der
                    Umgang mit eskalativen Verhalten unserer Klienten.
                  </p>
                  <StaticImage
                    src='../images/Eskalation.jpg'
                    alt='Eskalation'
                  />
                </div>
                <a
                  href='/Fortbildungsflyer_Lernen mit Rückenwind_Eskalation und Deeskalation.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span>
                    Mehr erfahren <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                </a>
              </StyledCourseInformation>
            </StyledCourse>
          </StyledCourseContainer>
        </Section>
        <Section heading='Supervision' anchor='supervision'>
          <p>
            Sabrina Liebich (M.A., BCBA) bietet Supervision auf Deutsch oder
            Englisch für angehende{' '}
            <a href='/bcba' target='_blank' rel='noreferrer'>
              BCaBAs oder BCBAs
            </a>{' '}
            (Board Certified Behavior Analyst) sowie für bereits zertifizierte
            BCaBAs (Board Certified assistant Behavior Analyst) an. Des Weiteren
            bietet Sabrina Liebich auch Supervision auf Deutsch oder Englisch
            für Personen in ihrer Weiterbildung zum{' '}
            <a href='/iba' target='_blank' rel='noreferrer'>
              IBA
            </a>{' '}
            (International Behavior Analyst) an.
          </p>
          <p>
            Sabrina Liebich (M.A., BCBA) offers supervision in German or English
            for prospective BCaBAs or BCBAs (Board Certified Behavior Analyst)
            as well as for already certified BCaBAs (Board Certified assistant
            Behavior Analyst).
          </p>
          <p>
            Furthermore, Sabrina Liebich also offers supervision in German or
            English for people in her further training to become an IBA
            (International Behavior Analyst).
          </p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Fortbildung;
